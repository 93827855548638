.image-order {
  position: relative;
  align-items: center;
  justify-content: center;
}
.delete-icon {
  cursor: pointer;
}

.wrapper {
  /* max-width: 1100px; */

  align-items: center;
  justify-content: center;
}

div.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 200px;
  height: 160px;
  position: relative;
  border-radius: 20px;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

div.desc {
  padding: 15px;
  text-align: center;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.desc img {
  width: 30px !important;
  height: 30px !important;
}
div.gallery:hover div.desc {
  opacity: 1;
  visibility: visible;
}
