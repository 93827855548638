.avatar{
    vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
#select_staff{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.avatarContainer{
  display: flex;
    flex-direction: column;
    margin: 16px 0px 0px 4px;
}
.h5{
  line-height: 11px;
}
.h6{
  line-height: 23px;
}